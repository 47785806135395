import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import browserUi from 'images/browser-ui.jpg';

const Root = styled.div`
  background-color: ${p => p.theme.blackDark};
  background: url(${browserUi}) no-repeat;
  background-size: cover;
  box-shadow: ${p => p.theme.boxShadowL};
  border-radius: ${p => p.theme.borderRadius};
  overflow: hidden;
  padding-top: 5.08%; /* Aspect ratio for the browser header (590 x 30) */
  position: relative;
  z-index: 1;
`;

const ImgWrapper = styled.div`
  position: relative;
  height: 0;
  padding-top: 56.25%;
  width: 100%;

  > * {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
`;

const Browser = ({ children }) => {
  return (
    <Root>
      <ImgWrapper>
        {children}
      </ImgWrapper>
    </Root>
  );
};

Browser.propTypes = {
  children: PropTypes.object,
};

export default Browser;
